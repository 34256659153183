import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { Metavalues } from 'component/Metafields'
import { Configuration } from 'store/Configuration'

@observer
export default class Configurator extends Component {
  static propTypes = {
    configuration: PropTypes.instanceOf(Configuration).isRequired,
  }

  render() {
    return (
      <Form>
        <h1>Edit configuration "Default"</h1>
        <Metavalues model={this.props.configuration} />
      </Form>
    )
  }
}
