import React from 'react'
import { observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { t } from 'i18next'
import { AdminOverview, ItemButton } from '@code-yellow/spider'
import { ArticleType } from 'store/ArticleType'
import { Configuration, ConfigurationStore } from 'store/Configuration'
import { Table } from 'semantic-ui-react'

const StyledTableRow = styled(Table.Row)`
  ${props => props.activeConfig && `
    background: #eee !important;
    background-color: #eee !important;
  `}
`

const StyledTable = styled(Table)`
`


// interface ConfigurationOverviewProps extends AdminOverviewProps {
//   target: ArticleType,
//   trigger: (triggerProps: { [key: string]: any }) => React.ReactNode;
//   onClose?: () => void;
// }

@observer
export default class ConfigurationOverview extends AdminOverview {
  static propTypes = {
    target: PropTypes.instanceOf(ArticleType).isRequired,
  }

  Model = Configuration
  TableRow = StyledTableRow
  Table= StyledTable

  store = new ConfigurationStore()
  modal = true
  bindUrlParams = true
  fetchOnMount = false

  tableProps() {
    return {
      ...super.tableProps,
      basic: 'very',
    }
  }

  rowProps(item, i) {
    return {
      ...super.rowProps(item, i),
      activeConfig: i === 0,
      'data-test-configuration': item?.id ?? i,
    }
  }

  settings = [
    'name',
    {
      label: t('configuration.field.version.label'),
      attr: ({ version }) => `v${version}`,
      sortKey: undefined,
      collapsing: true
    },
    { collapsing: true },
  ]

  sidebars = []

  buttons = () => {
    return [
      (configuration) => {
        return (
          <ItemButton compact
              data-test-edit-configuration={configuration.id}
              primary={false}
              label='Edit configuration'
              icon='edit'
            />
        )
      },
      (configuration) => {
        return (
          <ItemButton compact disabled
              data-test-delete-configuration={configuration.id}
              primary={false}
              label='Delete configuration'
              icon='delete'
            />
        )
      },
    ]
  }

  componentDidMount() {
    const res = super.componentDidMount()

    this.store.add(new Configuration({ name: 'Default' }).toJS())

    return res
  }

  render() {
    return this.renderOverviewTable()
  }
}
