import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ExactItem } from './ExactItem'
import { BatchTypeStore, TYPE_BUY } from './BatchType'
import { Classification } from './Classification'
import { ArticleTypeStorageLocationStore } from './ArticleTypeStorageLocation'
import { ArticleTypeWarehouseStore } from './ArticleTypeWarehouse'
import { BillOfMaterialVersionStore } from './BillOfMaterialVersion'
import { MetafieldStore } from './Metafield'
import { MetavalueStore } from './Metavalue'
import { StorageLocationStore } from './StorageLocation'


export class ArticleType extends Model {
  static backendResourceName = 'article_type'
  static omitFields = [
    'totalStock',
    'totalUnallocated',
    'futureStock',
    'futureUnallocated',
    'projectedStock',
  ]

  static idColor = 'purple'
  static idPrefix = 'foo'  // needed for admin overview check, removed by `getIdPrefix` override below

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  getIdIcon() {
    return 'cube'
  }

  getIdPrefix() {
    return ''
  }

  @observable id = null
  @observable name = ''
  @observable isAssembly = false
  @observable isMake = true
  @observable isBuy = false
  @observable isSerial = false
  @observable startDate = null
  @observable endDate = null
  @observable code = ''
  @observable deleted = false
  @observable myTrackBatchUsage = null
  @observable trackBatchUsage = null
  @observable unit = ''
  @observable extraDescription = ''
  @observable searchCode = ''
  @observable barcode = ''
  @observable volume = null
  @observable volumeFactor = null
  @observable size = 0

  // annotations
  @observable totalStock = 0
  @observable totalUnallocated = 0
  @observable futureStock = 0
  @observable futureUnallocated = 0
  @observable projectedStock = 0

  get _labelContent() {
    return this.code
  }

  getUrl() {
    return `/assets/article-type/${this.id}/edit`
  }

  relations() {
    return {
      exactItem: ExactItem,
      batchTypes: BatchTypeStore,
      storageLocations: ArticleTypeStorageLocationStore,
      classification: Classification,
      billOfMaterialVersions: BillOfMaterialVersionStore,
      warehouses: ArticleTypeWarehouseStore,
      metafields: MetafieldStore,
      metavalues: MetavalueStore,
      preferredLocations: StorageLocationStore,
    }
  }

  @computed
  get batchTypesBuy() {
    return this.batchTypes.filter((bt) => bt.type === TYPE_BUY)
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    if (this.isNew) {
      delete res.versions
    }
    return res
  }

  toBackendAll({ nestedRelations: { metavalues, ...nestedRelations } = {}, ...options } = {}) {
    const { data: [data], relations } =  super.toBackendAll({ nestedRelations, ...options })

    if (metavalues !== undefined) {
      delete data.metavalues

      // eslint-disable-next-line
      for (const metavalue of this.metavalues.models) {
        const { data: [subdata], relations: subrelations } = metavalue.toBackendAll({ nestedRelations: metavalues })
        delete subdata.metafield
        data[`metafield(${metavalue.metafield.id})`] = subdata

        // eslint-disable-next-line
        for (const [rel, reldata] of Object.entries(subrelations)) {
          if (relations[rel] === undefined) {
            relations[rel] = []
          }
          relations[rel].push(...reldata)
        }
      }
    }

    return { data: [data], relations }
  }

  casts() {
    return {
      startDate: Casts.date,
      endDate: Casts.date,
    }
  }

  async getSynergyDocumentIds(articleTypeCode, salesOrderNumber) {
    const params = {}
    if (articleTypeCode) {
      params['article_type_code'] = articleTypeCode
    }
    if (salesOrderNumber) {
      params['sales_order_number'] = salesOrderNumber
    }

    return await this.api.get('article_type/synergy_document_ids/', params)
  }

  isAssemblyItem() {
    return this.billOfMaterialVersions.length > 0 &&
      this.billOfMaterialVersions.models.some((p) => p.type === 'assembly_bom')
  }
}

export class ArticleTypeStore extends Store {
  Model = ArticleType
  static backendResourceName = 'article_type'
}
