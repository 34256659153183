import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, action, reaction, observable } from 'mobx'
import { observer } from 'mobx-react'
import { IconButton, TargetTextInput, TargetSelect, TargetCheckbox, TargetMultiTextInput, TargetNumberInput, TargetTextArea, TargetImage, TargetRadioButtons, StyledCheckbox } from '@code-yellow/spider'
import { Form, Dropdown, Divider } from 'semantic-ui-react'
import styled from 'styled-components'
import PlaceholderImage from 'image/placeholder-image.png'

// components
import TargetSerialNumberFormat from 'component/TargetSerialNumberFormat'
import NullableTargetTextInput from 'component/NullableTargetTextInput'
// end components

// helpers
import { BOOL_OPTIONS } from 'helpers'
// end helpers

// stores
import { Model, Store } from 'store/Base'
import { ArticleType } from 'store/ArticleType'
import { LoadCarrier } from 'store/LoadCarrier'
import { Classification } from 'store/Classification'
import { ProductionRequest } from 'store/ProductionRequest'
import { Metafield, MetafieldStore } from 'store/Metafield'
import { Configuration } from 'store/Configuration'
// end stores

export const LEVEL_PARENT = {
  classification: null,
  article_type: 'classification',
  production_request: 'article_type',
  load_carrier: null,
  configuration: 'classification',
}

const LEVEL_ANCESTORS = {}

function addAncestors(level) {
  if (LEVEL_ANCESTORS[level] !== undefined) {
    return
  }
  const parent = LEVEL_PARENT[level]
  if (parent === null) {
    LEVEL_ANCESTORS[level] = []
  } else {
    addAncestors(parent)
    LEVEL_ANCESTORS[level] = [...LEVEL_ANCESTORS[parent], parent]
  }
}

Object.keys(LEVEL_PARENT).forEach(addAncestors)

export function getLevel(model) {
  if (model instanceof ArticleType) {
    return 'article_type'
  } else if (model instanceof LoadCarrier) {
    return 'load_carrier'
  } else if (model instanceof Classification) {
    return 'classification'
  } else if (model instanceof ProductionRequest) {
    return 'production_request'
  } else if (model instanceof Configuration) {
    return 'configuration'
  } else {
    throw new Error('unknown level')
  }
}

export function getDescendants(level) {
  return Object.keys(LEVEL_PARENT).filter((levelParent) => LEVEL_ANCESTORS[levelParent].includes(level))
}

export function getDescendantsByModel(model) {
  const level = getLevel(model)
  return getDescendants(level)
}

const MetafieldLineContainer = styled.div`
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.5em;
  margin: 0 -0.5em;
`

const MetafieldLineHeader = styled.div`
  ${({ open }) => open && 'margin-bottom: 0.5em;'}
`

const MetafieldLineTitle = styled.span`
  ${({ isNew }) => isNew && `
    color: rgba(0, 0, 0, 0.5);
    font-style: italic;
  `}
`

const Indent = styled.div`
  border-left: 4px solid rgba(34, 36, 38, 0.15);
  padding: 0.75em 0 0.75em 1em;
  margin: -0.75em 0 1em;
  &:last-child {
    margin-bottom: 0;
  }
  > .fields:last-child {
    margin-bottom: 0 !important;
  }
`

const ConnectedGroup = styled(Form.Group)`
  margin: 0 0 1em !important;

  .field {
    padding: 0 !important;

    input,
    .selection.dropdown {
      border-radius: 0 !important;
    }

    &:first-child input,
    &:first-child .selection.dropdown {
      border-radius: 0.28571429rem 0 0 0.28571429rem !important;
    }

    &:last-child input,
    &:last-child .selection.dropdown {
      border-radius: 0 0.28571429rem 0.28571429rem 0 !important;
    }

    &:not(:last-child) input,
    &:not(:last-child) .selection.dropdown {
      border-right: none !important;
    }
  }
`


@observer
export class MetafieldLine extends Component {
  static propTypes = {
    metafield: PropTypes.instanceOf(Metafield).isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  @observable open = this.props.metafield.isNew

  render() {
    const { metafield, onDelete, ...props } = this.props
    return (
      <MetafieldLineContainer data-test-metafield {...props}>
        <MetafieldLineHeader open={this.open}>
          <MetafieldLineTitle isNew={metafield.name === ''}>
            {metafield.name === '' ? t('metafield.edit.new') : metafield.name}
          </MetafieldLineTitle>
          <IconButton
            data-test-metafield-delete
            name="trash alternate"
            style={{ float: 'right' }}
            onClick={() => onDelete(metafield)}
          />
          <IconButton
            data-test-metafield-toggle
            name={this.open ? 'chevron up' : 'chevron down'}
            style={{ float: 'right' }}
            onClick={() => this.open = !this.open}
          />
        </MetafieldLineHeader>
        {this.open && <MetafieldEdit metafield={metafield} />}
      </MetafieldLineContainer>
    )
  }
}

@observer
export class Metafields extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
    entryLevel: PropTypes.string,
    formProps: PropTypes.object,
  }

  @observable store = new MetafieldStore({ params: { limit: 'none' } })

  @computed get level() {
    const { model } = this.props
    return getLevel(model)
  }

  @computed get descendants() {
    return getDescendants(this.level)
  }

  @computed get groups() {
    const { model } = this.props

    const groups = Object.fromEntries(this.descendants.map((level) => [level, []]))

    // eslint-disable-next-line
    for (const metafield of model.metafields.models) {
      groups[metafield.entryLevel].push(metafield)
    }

    return groups
  }

  componentDidMount() {
    this.entryLevelReaction = reaction(
      () => this.props.entryLevel,
      action(() => {
        this.store.params['.entry_level'] = this.props.entryLevel ?? ''
        this.store.fetch()
      }),
      { fireImmediately: true }
    )
  }

  componentWillUnmount() {
    this.entryLevelReaction?.()
  }

  renderSelection = (entryLevel) => {
    const { model, formProps } = this.props

    // Group contains all enabled ("selected") metafields
    const group = this.groups[entryLevel]

    const metafieldsList = this.store.map(metafield => {
      // The metafield should show as selected if it's enabled on this model
      const mfSelected = (group.filter(selectedMf => selectedMf.id === metafield.id).length > 0)
      return (
        <Form.Field inline data-test-metafield-select={metafield.id}>
          <StyledCheckbox name='metafield-select' checked={mfSelected} onClick={(e, { checked }) => checked ? model.metafields.add(metafield.toJS()) : model.metafields.removeById(metafield.id)} />
          <label>{metafield.name}</label>
        </Form.Field>
      )
    })

    return (
      <Form {...formProps}>
        {metafieldsList}
      </Form>
    )
  }

  renderGroup = (entryLevel) => {
    const { model } = this.props
    const group = this.groups[entryLevel]

    return group.map((metafield) => (
      <MetafieldLine
        key={metafield.cid}
        metafield={metafield}
        onDelete={(metafield) => model.metafields.remove(metafield)}
      />
    ))
  }

  render() {
    const { model } = this.props
    return this.descendants.map((entryLevel) => (
      <Form.Field key={entryLevel} data-test-metafields={entryLevel}>
        <label>
          {t(`metafield.edit.entryLevel.${entryLevel}`, { count: this.groups[entryLevel].length })}
          <IconButton
            data-test-add-metafield
            name="add"
            style={{ float: 'right' }}
            onClick={() => model.metafields.add({ entryLevel })}
          />
        </label>
        {this.renderGroup(entryLevel)}
      </Form.Field>
    ))
  }
}

@observer
export class MetafieldEdit extends Component {
  static propTypes = {
    metafield: PropTypes.instanceOf(Metafield).isRequired,
  }

  renderMetafieldMetadataConfiguration = (metafield) => (
    <>
      <ConnectedGroup>
        <TargetTextInput target={metafield} name="name" />
        <TargetSelect
          target={metafield}
          name="type"
          options={Metafield.TYPES.map((type) => ({
            value: type,
            text: t(`metafield.field.type.value.${type}`),
          }))}
          onChange={action((type) => {
            metafield.setInput('type', type)

            let defaultValue = null

            if (metafield.type === 'text') {
              defaultValue = ''
            } else if (metafield.type === 'check') {
              defaultValue = false
            } else if (metafield.type === 'choice' && metafield.multiple) {
              defaultValue = []
            } else if (metafield.type === 'format') {
              defaultValue = ''
            }

            metafield.setInput('defaultValue', defaultValue)
            metafield.setInput('defaultFile', null)
          })}
        />
      </ConnectedGroup>
      <NullableTargetTextInput target={metafield} name="slug" />
    </>
  )

  renderMetafieldConfiguration = (metafield) => {
    if (!(metafield.type in this.metafieldTypeRenderers)) {
      return
    }

    const render = this.metafieldTypeRenderers[metafield.type]
    return render(metafield)
  }

  renderTextConfiguration = (metafield) => (
    <Indent>
      <TargetCheckbox noLabel rightLabel target={metafield} name="textLong" />
    </Indent>
  )

  renderChoiceConfiguration = (metafield) => (
    <Indent>
      <TargetMultiTextInput target={metafield} name="choiceOptions" />
      <TargetCheckbox noLabel rightLabel target={metafield} name="choiceMultiple" />
      <TargetCheckbox noLabel rightLabel target={metafield} name="choiceAllowAdditions" />
    </Indent>
  )

  renderMeasureConfiguration = (metafield) => (
    <Indent>
      <Form.Group widths="equal">
        <TargetNumberInput allowDecimal target={metafield} name="measureMin" />
        <TargetNumberInput allowDecimal target={metafield} name="measureMax" />
      </Form.Group>
    </Indent>
  )

  renderFormatConfiguration = (metafield) => (
    <Indent>
      <TargetSerialNumberFormat allowAnything target={metafield} name="formatFormat" />
    </Indent>
  )

  // Object to map metafield types to their respective render functions
  metafieldTypeRenderers = {
    text: this.renderTextConfiguration,
    choice: this.renderChoiceConfiguration,
    measure: this.renderMeasureConfiguration,
    format: this.renderFormatConfiguration,
  }

  render() {
    const { metafield } = this.props
    return (
      <>
        {this.renderMetafieldMetadataConfiguration(metafield)}
        {this.renderMetafieldConfiguration(metafield)}
        <TargetCheckbox noLabel rightLabel target={metafield} name="default" />
        {metafield.default && (
          <Indent>
            <MetafieldValue
              metafield={metafield}
              value={{
                value: metafield.defaultValue,
                file: metafield.defaultFile,
              }}
              onChange={action(({ value, file }) => {
                metafield.setInput('defaultValue', value)
                metafield.setInput('defaultFile', file)
              })}
              errors={[
                ...metafield.backendValidationErrors.defaultValue ?? [],
                ...metafield.backendValidationErrors.defaultFile ?? [],
              ]}
            />
          </Indent>
        )}
      </>
    )
  }
}

@observer
export class MetafieldValue extends Component {
  static propTypes = {
    label: PropTypes.node,
    metafield: PropTypes.instanceOf(Metafield).isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string.isRequired),
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    errors: [],
    disabled: false,
  }

  render() {
    const { label, metafield, value, onChange, errors, disabled } = this.props

    const props = {
      label,
      noLabel: !label,
      errors,
      'data-test-metafield': metafield.id,
      value: value.value,
      onChange: (value_) => onChange({ ...value, value: value_ }),
      disabled: disabled
    }

    if (metafield.type === 'text') {
      if (metafield.textLong) {
        return <TargetTextArea {...props} />
      } else {
        return <TargetTextInput {...props} />
      }
    } else if (metafield.type === 'check') {
      return <TargetCheckbox {...props} />
    } else if (metafield.type === 'image') {
      return (
        <TargetImage
          {...props}
          value={value.file}
          onChange={(file) => onChange({ ...value, file })}
          previewDefault={PlaceholderImage}
          previewDefaultFileType="image/png"
        />
      )
    } else if (metafield.type === 'choice') {
      let options = metafield.choiceOptions
      if (props.value !== null) {
        // eslint-disable-next-line
        for (const value of (metafield.choiceMultiple ? props.value : [props.value])) {
          if (!options.includes(value)) {
            if (options === metafield.choiceOptions) {
              options = options.slice()
            }
            options.push(value)
          }
        }
      }
      return (
        <TargetSelect search
          {...props}
          multiple={metafield.choiceMultiple}
          allowAdditions={metafield.choiceAllowAdditions}
          options={options.map((value) => ({ value, text: value }))}
        />
      )
    } else if (metafield.type === 'measure') {
      return (
        <erInput
          {...props}
          allowDecimal={true}
          fromTarget={(value) => typeof value !== 'number' ? '' : value.toString()}
          toTarget={(value) => {
            if (value === '') {
              return null
            }
            return value.includes('.') ? parseFloat(value) : parseInt(value)
          }}
        />
      )
    } else if (metafield.type === 'format') {
      return <TargetTextInput {...props} />
    } else {
      throw new Error(`unknown metafield type: ${metafield.type}`)
    }
  }
}

export const MetafieldsContext = React.createContext({
  metafields: [],
  parentMetafields: [],
})

const LEVEL_PARENT_PATH = {
  article_type: ['classification'],
  production_request: ['articleType'],
  configuration: ['classification'],
}
const LEVEL_PARENT_REVPATH = {
  article_type: ['article_types'],
  production_request: ['production_requests'],
  configuration: ['configurations'],
}

function hasRel(model, rel) {
  const relPrefix = rel + '.'
  return model.__activeRelations.some((activeRel) => activeRel === rel || activeRel.startsWith(relPrefix))
}

class MetafieldsComputedContext {
  constructor(provider) {
    this.provider = provider
  }

  @computed get _baseMetafields() {
    const { model } = this.provider.props

    const entryLevel = model === null ? null : getLevel(model)
    const metafields = []
    const parentMetafields = []

    // eslint-disable-next-line
    for (const { store } of this.provider.stores) {
      // eslint-disable-next-line
      for (const metafield of store.models) {
        if (metafield.entryLevel === entryLevel) {
          metafields.push(metafield)
        } else {
          parentMetafields.push(metafield)
        }
      }
    }

    return { metafields, parentMetafields }
  }

  @computed get metafields() {
    return this._baseMetafields.metafields
  }

  @computed get parentMetafields() {
    return this._baseMetafields.parentMetafields
  }
}

@observer
export class MetafieldsProvider extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
    children: PropTypes.node,
    includeParentLevels: PropTypes.bool,
    onlyUsedInProcess: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    includeParentLevels: false,
    onlyUsedInProcess: false,
  }

  constructor(...args) {
    super(...args)
    this.contextValue = new MetafieldsComputedContext(this)
  }

  componentDidMount() {
    this.modelReaction = reaction(
      () => {
        const { model, includeParentLevels, onlyUsedInProcess } = this.props
        return model === null ? null : `${model.cid},${includeParentLevels},${onlyUsedInProcess}`
      },
      action(() => {
        const { model, includeParentLevels, onlyUsedInProcess } = this.props

        // eslint-disable-next-line
        for (const { cancel } of this.stores) {
          cancel()
        }


        if (model === null) {
          this.stores.clear() // = []
          return
        }

        const entryLevel = getLevel(model)
        const ancestors = LEVEL_ANCESTORS[entryLevel].length > 0 ? [...LEVEL_ANCESTORS[entryLevel]] : [null]

        // Ancestors previously contained null and any real ancestors. Null would
        // render all global metafields, the real ancestors would add the rest.
        // Now, render "global" metafields for all items without ancestors, and only
        // the selection for items with ancestors.

        // Map over the ancestors of the entry level of this model to determine
        // which metafields are relevant (enabled on a parent level) and should be shown
        this.stores = ancestors.map((enablingLevel, i) => {
          const entryLevels = (
            includeParentLevels
            ? [...ancestors.slice(i + 1), entryLevel]
            : [entryLevel]
          )
          const store = new MetafieldStore({
            params: {
              limit: 'none',
              ...entryLevels.length === 1 ? {
                '.entry_level': entryLevels[0],
              } : {
                '.entry_level:in': entryLevels.join(',')
              },
            },
            relations: ['enabledForArticleTypes', 'enabledForClassifications'],
          })

          let cancel
          if (enablingLevel === null) {
            if (entryLevel === 'production_request' && hasRel(model, 'processVersion')) {
              cancel = reaction(
                () => model.processVersion.id,
                action((processVersionId) => {
                  if (this.props.model !== model) {
                    return
                  }

                  store.clear()
                  if (!processVersionId) {
                    return
                  }
                  store.params['.form_fields.form.step.process_version:not'] = processVersionId.toString()
                  if (onlyUsedInProcess) {
                    store.params['.right_scan_constraints.form_field.form.step.process_version'] = processVersionId.toString()
                  }
                  store.fetch()
                }),
                { fireImmediately: true },
              )
            } else {
              store.fetch()
              cancel = () => {}
            }
          } else {
            const path = []
            const revpath = []

            let level = entryLevel
            while (level !== enablingLevel) {
              path.push(...LEVEL_PARENT_PATH[level])
              revpath.push(...LEVEL_PARENT_REVPATH[level])
              level = LEVEL_PARENT[level]
            }

            let local = 0
            while (local < path.length && hasRel(model, path.slice(0, local + 1).join('.'))) {
              local++
            }

            if (
              local === path.length &&
              hasRel(model, path.join('.') + '.metafields') &&
              !(entryLevel === 'production_request' && hasRel(model, 'processVersion'))
            ) {
              cancel = reaction(
                () => {
                  let source = model
                  // eslint-disable-next-line
                  for (const field of path) {
                    source = source[field]
                  }
                  return source.metafields.map(({ cid }) => cid).join(',')
                },
                action(() => {
                  if (this.props.model !== model) {
                    return
                  }

                  let source = model
                  // eslint-disable-next-line
                  for (const field of path) {
                    source = source[field]
                  }
                  store.models.replace(source.metafields.models)
                }),
                { fireImmediately: true },
              )
            } else {
              cancel = reaction(
                () => {
                  let source = model
                  // eslint-disable-next-line
                  for (const field of path.slice(0, local)) {
                    source = source[field]
                  }
                  if (entryLevel === 'production_request' && hasRel(model, 'processVersion')) {
                    return JSON.stringify([source.id, model.processVersion.id])
                  } else {
                    return source.id
                  }
                },
                action((sourceId) => {
                  if (this.props.model !== model) {
                    return
                  }

                  store.clear()

                  if (entryLevel === 'production_request' && hasRel(model, 'processVersion')) {
                    let processVersionId
                    [sourceId, processVersionId] = JSON.parse(sourceId)
                    if (!processVersionId) {
                      return
                    }
                    store.params['.form_fields.form.step.process_version:not'] = processVersionId.toString()
                    if (onlyUsedInProcess) {
                      store.params['.right_scan_constraints.form_field.form.step.process_version'] = processVersionId.toString()
                    }
                  }

                  if (!sourceId) {
                    return
                  }
                  let key = `.enabled_for_${enablingLevel}s`
                  // eslint-disable-next-line
                  for (const field of revpath.slice(local).reverse()) {
                    key += `.${field}`
                  }
                  store.params[key] = sourceId.toString()
                  store.fetch().then(() => console.log(store))
                }),
                { fireImmediately: true },
              )
            }
          }

          return { store, cancel }
        })
      }),
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    this.modelReaction()
    // eslint-disable-next-line
    for (const { cancel } of this.stores) {
      cancel()
    }
  }

  @observable stores = []

  render() {
    const { children } = this.props
    return (
      <MetafieldsContext.Provider value={this.contextValue}>
        {children}
      </MetafieldsContext.Provider>
    )
  }
}

@observer
export class Metavalues extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
    useContext: PropTypes.bool,
  }

  static defaultProps = {
    useContext: false,
  }

  render() {
    const { model, useContext, ...props } = this.props

    let node = <MetavaluesBase model={model} {...props} />
    if (!useContext) {
      node = <MetafieldsProvider model={model}>{node}</MetafieldsProvider>
    }
    return node
  }
}

@observer
class MetavaluesBase extends Component {
  static propTypes = {
    model: PropTypes.instanceOf(Model).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  static contextType = MetafieldsContext

  componentDidMount() {
    this.metafieldsReaction = reaction(
      () => `${this.props.model.cid}:${this.props.model.isLoading}:${this.context.metafields.map(({ id }) => id).join(',')}`,
      action(() => {
        const { model } = this.props
        if (model.isLoading) {
          return
        }
        // eslint-disable-next-line
        for (const metafield of this.context.metafields) {
          if (!model.metavalues.models.some((metavalue) => metavalue.metafield.id === metafield.id)) {
            model.metavalues.add({
              metafield: metafield.toJS(),
              value: metafield.defaultValue,
              file: metafield.defaultFile,
            })
          }
        }
      }),
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    this.metafieldsReaction()
  }

  render() {
    const { model, disabled } = this.props
    return this.context.metafields.map((metafield) => {
      const metavalue = model.metavalues.find((metavalue) => metavalue.metafield.id === metafield.id)
      return (
        <MetafieldValue
          key={metafield.cid}
          label={metafield.name}
          metafield={metafield}
          value={
            metavalue === undefined
            ? { value: null, file: null }
            : { value: metavalue.value, file: metavalue.file }
          }
          onChange={action(({ value, file }) => {
            if (metavalue === undefined) {
              model.metavalues.add({
                metafield: metafield.toJS(),
                value,
                file,
              })
            } else {
              metavalue.setInput('value', value)
              metavalue.setInput('file', file)
            }
          })}
          errors={[
            ...metavalue?.backendValidationErrors?.value ?? [],
            ...metavalue?.backendValidationErrors?.file ?? [],
          ]}
          disabled={disabled}
        />
      )
    })
  }
}

function addPrefix(prefix, filters) {
  return Object.fromEntries(Object.entries(filters).map(([level, filter]) => [level, prefix + filter]))
}

function getFilters(level) {
  switch (level) {
    case 'classification':
      return {
        classification: '',
      }
    case 'article_type':
      return {
        article_type: '',
        ...addPrefix('classification.', getFilters('classification')),
      }
    case 'load_carrier':
      return {
        load_carrier: '',
      }
    case 'production_request':
      return {
        ...addPrefix('process_version.batch_type.article_type.', getFilters('article_type')),
        production_request: '',
      }
    case 'batch':
      return {
        ...addPrefix('batch_type.article_type.', getFilters('article_type')),
        ...addPrefix('load_carrier.', getFilters('load_carrier')),
        production_request: '',
      }
    default:
      return {}
  }
}

@observer class TargetNumberFilter extends Component {
  static propTypes = TargetNumberInput.propTypes

  quantifiers = [
    { value: ':gte', text: t('form.greaterThanOrEqual') },
    { value: ':lte', text: t('form.lowerThanOrEqual') },
  ]
  @observable quantifier = (
    this.quantifiers.find(({ value }) => (
      this.props.target.params[this.props.name + value] !== undefined
    )) ??
    this.quantifiers[0]
  ).value

  render() {
    const { name, afterChange, ...props } = this.props
    return (
      <TargetNumberInput
        {...props}
        name={name + this.quantifier}
        contentProps={{
          label: (
            <Dropdown
              value={this.quantifier}
              onChange={action((e, { value }) => {
                const { target, name } = this.props
                if (value !== this.quantifier && target.params[name + this.quantifier]) {
                  target.params[name + value] = target.params[name + this.quantifier]
                  delete target.params[name + this.quantifier]
                  afterChange()
                }
                this.quantifier = value
              })}
              options={this.quantifiers}
            />
          ),
          labelPosition: 'left',
        }}
      />
    )
  }
}

@observer
export class Metafilters extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(Store).isRequired,
    fetch: PropTypes.func.isRequired,
    debouncedFetch: PropTypes.func.isRequired,
    withDivider: PropTypes.bool,
  }

  @computed get filters() {
    const { store } = this.props
    return getFilters(store.constructor.backendResourceName)
  }

  metafields = new MetafieldStore()

  componentDidMount() {
    this.levelsReaction = reaction(
      () => Object.keys(this.filters).sort().join(','),
      action((levels) => {
        this.metafields.clear()
        this.metafields.params['.entry_level:in'] = levels
        this.metafields.fetch()
      }),
      { fireImmediately: true },
    )
  }

  componentWillUnmount() {
    this.levelsReaction()
  }

  render() {
    const { store, fetch, debouncedFetch, withDivider } = this.props
    const showDivider = withDivider && this.metafields.length > 0

    return (showDivider ? [<Divider horizontal data-test-metafield-divider>Metafields</Divider>] : []).concat(
    this.metafields.map((metafield) => {
      const props = {
        key: metafield.id,
        'data-test-metafield-filter': metafield.id,
        label: metafield.name,
        target: store,
        name: `.${this.filters[metafield.entryLevel]}metafield(${metafield.id})`,
        afterChange: fetch,
      }

      if (metafield.type === 'text') {
        return (
          <TargetTextInput
            {...props}
            name={props.name + ':icontains'}
            afterChange={debouncedFetch}
          />
        )
      } else if (metafield.type === 'check') {
        return (
          <TargetRadioButtons
            {...props}
            options={BOOL_OPTIONS}
          />
        )
      } else if (metafield.type === 'image') {
        return (
          <TargetRadioButtons
            {...props}
            name={props.name + ':isnull'}
            options={BOOL_OPTIONS}
          />
        )
      } else if (metafield.type === 'choice') {
        return (
          <TargetSelect
            {...props}
            multiple search
            options={metafield.choiceOptions.map((value) => ({ value, text: value }))}
            name={props.name + (metafield.choiceMultiple ? ':overlap' : ':in')}
          />
        )
      } else if (metafield.type === 'measure') {
        return (
          <TargetNumberFilter
            {...props}
            allowDecimal={true}
            afterChange={debouncedFetch}
          />
        )
      } else if (metafield.type === 'format') {
        return (
          <TargetTextInput
            {...props}
            name={props.name + ':icontains'}
            afterChange={debouncedFetch}
          />
        )
      } else {
        throw new Error(`unknown metafield type: ${metafield.type}`)
      }
    }))
  }
}

@observer
export class IfMetafieldsExist extends Component {
  static propTypes = {
    children: PropTypes.node,
    minFields: PropTypes.number,
    maxFields: PropTypes.number,
  }

  static defaultProps = {
    children: null,
    minFields: 1,
    maxFields: null,
  }

  static contextType = MetafieldsContext

  render() {
    const { minFields, maxFields, children } = this.props
    const fields = this.context.metafields.length
    return (
      (minFields === null || fields >= minFields) &&
      (maxFields === null || fields <= maxFields) &&
      children
    )
  }
}
